<script setup lang="ts">
import type { Filter } from '@/types/jobModel';

defineOptions({
  name: 'MopJobFilter',
});

const props = defineProps({
  filterType: {
    type: String,
    required: true,
  },
  headline: {
    type: String,
    default: '',
  },
  filters: {
    type: Array as PropType<Filter[]>,
    default: () => [],
  },
  showEmpty: {
    type: Boolean,
    default: false,
  },
});

const { $gtm } = useNuxtApp();
const { handleBuildUrlParams, isFilterApplied } = useMopJob();
const isOpenRef = ref(isFilterApplied(props.filterType));

const filtersRef = computed(() => {
  return props.filters.filter((filter) => {
    return props.showEmpty ? true : filter.count > 0;
  });
});

function toggleOpen() {
  isOpenRef.value = !isOpenRef.value;
}

function handleVacancyFilterTracking(filter: Filter) {
  $gtm.trackEvent({
    eventName: 'vacancy_filter',
    parameters: ['applied', props.filterType, filter.name],
  });
}
</script>

<template>
  <div
    v-if="headline !== '' && filters.length > 0"
    class="job-filter"
    :class="{
      'job-filter--opened': isOpenRef,
    }"
  >
    <h3 class="job-filter__headline" @click="toggleOpen">
      {{ $mopI18n.t(`locale.filter.${headline}`) }}
    </h3>
    <div v-if="isOpenRef" class="job-filter__items">
      <NuxtLink
        v-for="(filter, i) in filtersRef"
        :key="`${filter.id}-${i}`"
        class="job-filter__item"
        :class="{
          'job-filter__item--active': isFilterApplied(filterType, filter.id),
          'job-filter__item--empty': !filter.count,
        }"
        :to="{
          path: $mopI18n.localePath($urls.CAREER_PAGE),
          query: handleBuildUrlParams({
            filter: { [filterType]: filter.id },
            page: 1,
          }).query,
        }"
        no-prefetch
        @click="handleVacancyFilterTracking(filter)"
      >
        {{ filter.name }} ({{ filter.count }})
      </NuxtLink>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.job-filter {
  margin-top: $space5;
}

.job-filter__headline {
  @include text-style(strong);

  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;
  border: 0;
  padding: 0;
  margin: 0;
  background: transparent;

  &::after {
    @include icon('arrow-select');

    content: '';
    width: 7px;
    height: 7px;
    border-width: 2px;
    margin-left: $space10;
    margin-top: 2px;
    transition: transform ease 0.3s;
  }
}

.job-filter--opened {
  .job-filter__headline::after {
    transform: rotateZ(-135deg);
  }
}

.job-filter__items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: $space10;
  margin-bottom: $space10;
}

.job-filter__item {
  @include link-not-underlined(2px);
}

.job-filter__item--empty {
  opacity: $disabled-opacity;
}
</style>
